/* eslint-disable */

import React from "react";
import { useTranslation } from "react-i18next";
import "./Footer.css";

function Footer() {
  const { t, i18n } = useTranslation();
  const changeLanguage = (code: string) => {
    i18n.changeLanguage(code);
  };
  return (
    <div className="footer">
      <div className="footerRow">
        <div className="footerContent">
          <p className="item pointer" onClick={() => changeLanguage("de")}>
            {t("translation:de")}
          </p>
          <p className="item pointer" onClick={() => changeLanguage("en")}>
            {" "}
            {t("translation:en")}
          </p>
          <p className="item">
            V{process.env.REACT_APP_APP_VERSION} - &copy;{(new Date().getFullYear())} Ruma GmbH
          </p>
        </div>
      </div>
    </div>
  );
}

export default Footer;
