import i18n from "i18next";
import { initReactI18next } from "react-i18next";

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    // the translations
    // (tip move them in a JSON file and import them,
    // or even better, manage them via a UI: https://react.i18next.com/guides/multiple-translation-files#manage-your-translations-with-a-management-gui)
    resources: {
      en: {
        translation: {
          welcome: "Welcome",
          download: "Download File",
          password: "Password",
          body: "You should have received your password by SMS. Please enter it and download your data.",
          error: "Something went wrong!",
          errorPassword: "Please enter your password!",
          wrongPassword: "Wrong password for decryption",
        },
      },
      de: {
        translation: {
          welcome: "Willkommen",
          download: "Datei herunterladen",
          password: "Passwort",
          body: "Sie sollten Ihr Passwort per SMS erhalten haben. Bitte geben Sie es ein und laden Sie Ihre Daten herunter.",
          error: "Etwas ist schief gelaufen.",
          errorPassword: "Bitte geben Sie Ihr Passwort ein!",
          wrongPassword: "Falsches Passwort zum Entschlüsseln",
        },
      },
    },
    lng: "de", // if you're using a language detector, do not define the lng option
    fallbackLng: "en",
    interpolation: {
      escapeValue: false, // react already safes from xss => https://www.i18next.com/translation-function/interpolation#unescape
    },
  });

export default i18n;
