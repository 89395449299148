import React from "react";
import "./App.css";
import { BrowserRouter as Router, Route } from "react-router-dom";
import Display from "./components/Display/Display";
import DisplayS3 from "./components/DisplayS3/DisplayS3";
import Footer from "./components/Footer/Footer";

const App: React.FC = () => {
  return (
    <div className="box">
      <Router>
        <Route path="/:id">
          <Display />
        </Route>
        <Route path="/">
          <DisplayS3 />
        </Route>
      </Router>
      <Footer />
    </div>
  );
};

export default App;
