/* eslint-disable */
import React from "react";
import "./ErrorMessage.css";

type Props = {
  text: string;
  removeError: (value: string | undefined) => void;
};
function ErrorMessage({ text, removeError }: Props) {
  return (
    <div className="errorBox">
      <p className="errorText">{text}</p>
      <p
        className="X"
        onClick={() => {
          return removeError(undefined);
        }}>
        X
      </p>
    </div>
  );
}

export default ErrorMessage;
