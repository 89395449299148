/* eslint-disable */
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import saveAs from "file-saver";
import CryptoJS from "crypto-js";
import Logo from "./ruma_logo.jpg";
import "./Display.css";
import ErrorMessage from "../Error/ErrorMessage";
import { useTranslation } from "react-i18next";
import JSZip from "jszip";

function Display() {
  const url = new URL(window.location.href);
  const exportURL = url.searchParams.get('url');

  const [fileB64, setFileB64] = useState<string>();
  const [error, setError] = useState<string>();
  const [password, setPassword] = useState<string>();

  const { t } = useTranslation();

  useEffect(() => {
    const fetchFile = async () => {
      if (!exportURL) {
        return
      }
      let url = new URL(exportURL);

      if (!url.hostname.endsWith('.amazonaws.com')) {
        return;
      }

      try {
        const res = await fetch(url.href);

        if (res.status === 200) {
          const text = await res.text();
          setFileB64(text);
        } else {
          setError(t('error'));
        }
      } catch (e) {
        setError(t('error'));
      }
    };
    fetchFile();
  }, [exportURL]);

  // @ts-ignore
  function convertWordArrayToUint8Array(wordArray) {
    var arrayOfWords = wordArray.hasOwnProperty("words") ? wordArray.words : [];
    var length = wordArray.hasOwnProperty("sigBytes")
      ? wordArray.sigBytes
      : arrayOfWords.length * 4;
    var uInt8Array = new Uint8Array(length),
      index = 0,
      word,
      i;
    for (i = 0; i < length; i++) {
      word = arrayOfWords[i];
      uInt8Array[index++] = word >> 24;
      uInt8Array[index++] = (word >> 16) & 0xff;
      uInt8Array[index++] = (word >> 8) & 0xff;
      uInt8Array[index++] = word & 0xff;
    }
    return uInt8Array;
  }

  const b64toBlob = (b64Data: string, contentType = '', sliceSize = 512) => {
    const atob = require('atob');
    const byteCharacters = atob(b64Data);
    const byteArrays = [];

    for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      const slice = byteCharacters.slice(offset, offset + sliceSize);

      const byteNumbers = new Array(slice.length);
      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }

      const byteArray = new Uint8Array(byteNumbers);
      byteArrays.push(byteArray);
    }

    const blob = new Blob(byteArrays, { type: contentType });
    return blob;
  }

  const loadFile = async () => {
    if (!password) {
      setError(t("errorPassword"));
      return;
    }
    if (fileB64) {
      // @ts-ignore
      var decrypted = CryptoJS.AES.decrypt(
        // @ts-ignore
        fileB64,
        password,
      ).toString(CryptoJS.enc.Latin1);

      const blob = b64toBlob(decrypted, 'application/x-zip-compressed');

      try {
        await JSZip.loadAsync(blob);

        // @ts-ignore
        saveAs(blob, "userdata.zip");
      } catch (error) {
        setError(t("wrongPassword"));
      }
    } else {
      setError(t("error"));
    }
  };

  return (
    <div className="container">
      <div className="centerBox">
        <a href="https://www.marker-test.de/">
          <img className="logo" src={Logo} alt="ruma_logo" />
        </a>
        <div className="width-full">
          <h1 className="text">{t("welcome")}</h1>
          <p className="text">{t("body")}</p>
          {error && <ErrorMessage removeError={setError} text={error} />}

          <input
            id="password"
            placeholder={t("password")}
            onChange={(e) => {
              setPassword(e.target.value);
            }}
          />
        </div>
        {fileB64 && (
          <button className="dbutton" type="button" onClick={loadFile}>
            {t("download")}
          </button>
        )}
      </div>
    </div>
  );
}

export default Display;
